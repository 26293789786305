<template>
  <b-modal
    v-model="showModal"
    data-testid="modal-create-BCId"
    no-close-on-backdrop
    centered
    :title="$t('account.modalCreateAdAccount.titleModal')"
    hide-footer
    content-class="business-content"
    header-class="business-header"
    body-class="business-body"
    @close="handleCloseModal"
  >
    <div>
      <validation-observer ref="businessFormValidation">
        <b-form
          @submit.prevent="submit"
        >
          <b-form-group
            :label="$t('myAdAccountsPage.setupTab.step3.labelBusinessCenterName')"
            data-testid="field-BCId-name"
            class="form-label"
          >
            <validation-provider
              #default="{ errors }"
              id="name"
              data-testid="name-BCId"
              rules="required"
              :name="$t('myAdAccountsPage.setupTab.step3.labelBusinessCenterName')"
            >
              <b-input-group
                data-testid="group-BCId"
                class="input-group-merge width-custom w-100"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model="businessName"
                  data-testid="input-name-BC"
                  class="input-height"
                  :placeholder="$t('account.modalCreateAdAccount.placeHolderBusinessCenterName')"
                  :state="errors.length > 0 ? false : null"
                  @keyup="currentIndex = null"
                />
              </b-input-group>
              <small
                v-if="errors[0]"
                data-testid="error-BC-name"
                class="text-danger d-block"
                style="margin-top: 10px"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            rules="required"
            :name="$t('account.modalCreateAdAccount.labelTimezone')"
          >
            <b-form-group :state="errors.length > 0 ? false : null">
              <div data-testid="field-timezone">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('account.modalCreateAdAccount.labelTimezone') }}</label>
                </div>
                <v-select
                  v-model="timeZone"
                  data-testid="timezone-select"
                  class="timezone-select"
                  :options="listTimeZone"
                  :clearable="false"
                  :placeholder="$t('account.modalCreateAdAccount.placeholderTimezone')"
                  @input="handleSelectTimeZone"
                >
                  <template #open-indicator="{ attributes }">
                    <span
                      data-testid="chevron-icon"
                      v-bind="attributes"
                    >
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="20"
                      />
                    </span>
                  </template>
                </v-select>
                <small
                  data-testid="error-timezone"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </div>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            rules="required"
            :name="$t('account.modalCreateAdAccount.labelRegisterArea')"
          >
            <b-form-group :state="errors.length > 0 ? false : null">
              <div data-testid="field-register-area">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('account.modalCreateAdAccount.labelRegisterArea') }}</label>
                </div>
                <v-select
                  v-model="registerAreaSelected"
                  id="select-register-area"
                  data-testid="select-register-area"
                  label="name"
                  class="timezone-select"
                  :placeholder="$t('account.modalCreateAdAccount.placeholderRegisterArea')"
                  :clearable="false"
                  :options="listRegisterArea"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="20"
                      />
                    </span>
                  </template>

                  <template #option="{ name, value }">
                    <strong
                      :id="`plan-${value}`"
                      :data-testid="`plan-${value}`"
                    >{{ name }}</strong>
                  </template>
                </v-select>
                <small
                  data-testid="error-timezone"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </div>
            </b-form-group>
          </validation-provider>
          <div data-testid="form-contact-info">
            <p class="mt-2 title-content-step">
              {{ $t('profile.textContactInfo') }}
            </p>
            <b-row>
              <b-col
                md="12"
                cols="12"
              >
                <b-form-group class="w-100">
                  <div class="d-flex justify-content-between">
                    <label>{{ $t('account.modalCreateAdAccount.labelPhoneNumber') }}</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('account.modalCreateAdAccount.labelPhoneNumber')"
                  >
                    <b-input-group
                      class="input-filter"
                    >
                      <b-input-group-prepend style="width: 30%">
                        <v-select
                          v-model="phoneRegionSelected"
                          id="select-phone region"
                          label="name"
                          :clearable="false"
                          class="d-inline-block input-group-merge width-custom input-height phone-region-select"
                          data-testid="phone-region-option"
                          :options="phoneRegionOptions"
                          @input="handleChangePhoneRegion"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                          <template #selected-option="{ callingCode, value }">
                            <span style="margin-right: 4px">{{ value }}</span><span>{{ callingCode }}</span>
                          </template>
                          <template #option="{ callingCode, name, value }">
                            <div class="d-flex justify-content-between">
                              <span>{{ name }}({{ value }})</span>
                              <span>{{ callingCode }}</span>
                            </div>
                          </template>
                        </v-select>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="contactInfo.phone"
                        data-testid="input-phone-number"
                        :placeholder="$t('account.modalCreateAdAccount.labelPhoneNumber')"
                        :class="{'phone-field-error': errorPhoneNumber}"
                        class="d-inline-block input-group-merge width-custom input-height"
                        @wheel="handleMouseWheelInput"
                        @keyup="validatePhoneNumber"
                      />
                    </b-input-group>
                    <small
                      v-if="errors[0] || errorPhoneNumber"
                      data-testid="error-phone-number"
                      class="text-danger d-block"
                      style="margin-top: 10px"
                    >
                      {{ errors[0] || errorPhoneNumber }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                cols="12"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('account.modalCreateAdAccount.labelIndustry')"
                >
                  <b-form-group :state="errors.length > 0 ? false : null">
                    <div data-testid="field-industry">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t('account.modalCreateAdAccount.labelIndustry') }}</label>
                      </div>
                      <b-form-select
                        v-model="secondaryIndustrySelected"
                        id="select-industry"
                        data-testid="select-industry"
                        class="timezone-select select-industry"
                        :class="{'text-placeholder': !secondaryIndustrySelected}"
                        @input="handleChangeSecondaryIndustry"
                      >
                        <b-form-select-option
                          v-for="parent in industryOptions.filter(item => !item.group)"
                          :key="parent.value"
                          :value="parent.value"
                          class="hidden"
                        >
                          {{ parent.name }}
                        </b-form-select-option>
                        <b-form-select-option-group
                          v-for="parent in industryOptions.filter(item => item.group)"
                          :key="parent.group"
                          :label="parent.group"
                        >
                          <b-form-select-option
                            v-for="(child, index) in parent.options"
                            :key="index"
                            :value="child.value"
                          >
                            {{ child.name }}
                          </b-form-select-option>
                        </b-form-select-option-group>
                      </b-form-select>
                      <small
                        data-testid="error-industry"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>

          <div
            v-if="!businessInfo"
            data-testid="form-business-info"
          >
            <p class="mt-2 title-content-step">
              {{ $t('profile.textGeneral') }}
            </p>
            <b-row>
              <b-col
                md="12"
                cols="12"
              >
                <b-form-group
                  :label="$t('myAdAccountsPage.labelYourBusinessName')"
                  data-testid="label-business-name"
                  class="form-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('myAdAccountsPage.labelYourBusinessName')"
                  >
                    <b-input-group
                      class="input-group-merge width-custom w-100"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        v-model.trim="yourBusinessName"
                        data-testid="input-business-name"
                        class="input-height"
                        :placeholder="$t('account.modalCreateAdAccount.placeholderBusinessName')"
                        :disabled="checkPlanUserToDisableInput"
                        :state="errors.length > 0 ? false : null"
                        @keyup="currentIndex = null"
                      />
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      data-testid="error-business-name-step3"
                      class="text-danger d-block"
                      style="margin-top: 10px"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                cols="12"
              >
                <b-form-group
                  :label="$t('myAdAccountsPage.labelCompanyWebsite')"
                  data-testid="label-company-website"
                  class="form-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="url|verifyBusinessWebsite|required"
                    :name="$t('myAdAccountsPage.labelCompanyWebsite')"
                  >
                    <b-input-group
                      class="input-group-merge width-custom w-100"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        v-model.trim="companyWebsite"
                        data-testid="input-company-website"
                        class="input-height"
                        :placeholder="$t('account.modalCreateAdAccount.placeholderCompanyWebsite')"
                        :disabled="checkPlanUserToDisableInput"
                        :state="errors.length > 0 ? false : null"
                        :type="url"
                        @keyup="currentIndex = null"
                      />
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      data-testid="error-company-website"
                      class="text-danger d-block"
                      style="margin-top: 10px"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div
            v-if="errorStep2"
            data-testid="error-create-BCId"
          >
            <error-tag :error="errorStep2" />
          </div>

          <btn-loading
            data-testid="btn-create-new-account-2"
            class="mt-2 btn-height"
            type="submit"
            variant-convert="btn-submit"
            pill
            :loading="loading"
          >
            {{ $t('account.modalCreateAdAccount.btnCreateNewAccount') }}
          </btn-loading>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup, BCol, BRow,
  BInputGroupPrepend,
  BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, url } from '@validations'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { toastification } from '@core/mixins/toast'
import errorTag from '@/components/errorTag.vue'
import envMixin from '@/mixins/envMixin'
import vSelect from 'vue-select'

const { mapActions, mapGetters } = createNamespacedHelpers('adsAccount')
const { mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BRow,
    BCol,
    errorTag,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BtnLoading,
    vSelect,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormSelectOptionGroup,
  },

  mixins: [generalConfigsMixin, toastification, envMixin],

  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showModal: this.show,
      businessName: '',
      required,
      timeZone: '',
      errorStep2: '',
      url,
      phoneRegionSelected: { name: 'VietNam', value: 'VN', callingCode: '+84' },
      contactInfo: {
        phoneRegionCode: 'VN',
        phone: '',
        secondaryIndustry: '',
      },
      yourBusinessName: null,
      companyWebsite: null,
      registerAreaSelected: null,

      secondaryIndustrySelected: null,

      errorPhoneNumber: '',
    }
  },

  computed: {
    ...mapGetters(['loading', 'message', 'status', 'listCountryRegion', 'listPhoneRegion', 'listSecondaryIndustry']),
    businessInfo() {
      return this.user?.data?.businessInfo
    },

    phoneRegionOptions() {
      return this.listPhoneRegion.map(item => ({
        name: item.phone_region_name,
        value: item.phone_region_code,
        callingCode: item.phone_region_calling_code,
      }))
    },

    industryOptions() {
      const first = { value: null, name: this.$t('account.modalCreateAdAccount.placeholderIndustry') }
      return [first, ...this.listSecondaryIndustry.map(parent => ({
        group: parent.parentIndustry,
        options: parent.children.map(child => (
          {
            name: child.industryName,
            value: child.industryID,
          }
        )),
      }))]
    },

    listRegisterArea() {
      return this.listCountryRegion.map(item => ({
        name: item.country,
        value: item.countryCode,
      }))
    },

    checkPlanUserToDisableInput() {
      return this.whiteAgencyPlan
        || (this.activePlanUser && this.isLimitAccount)
        || this.isLimitAccount
    },
  },

  watch: {
    show(value) {
      this.showModal = value
    },

    showModal(value) {
      if (!value) {
        this.$emit('close-modal')
      }
    },

    user: {
      handler() {
        if (this.user?.data?.businessInfo) {
          this.yourBusinessName = this.user?.data?.businessInfo?.name
          this.companyWebsite = this.user?.data?.businessInfo?.website
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.setTimezone()
    this.getCountryRegion()
    this.getPhoneRegion()
    this.getSecondaryIndustries()
  },

  methods: {
    ...mapActions(['createBusinessAccount', 'getBusinessAccount', 'getCountryRegion', 'getPhoneRegion', 'getSecondaryIndustries']),
    ...mapActionsAuth(['getAccountInfo']),
    handleMouseWheelInput(e) {
      e.target.blur()
    },

    setTimezone() {
      if (this.isEmediaPlatform) {
        this.timeZone = '(UTC+00:00) Africa/Casablanca'
      } else {
        this.timeZone = ''
      }
    },

    handleSelectTimeZone(value) {
      this.timeZone = value
    },

    handleChangePhoneRegion(value) {
      this.contactInfo.phoneRegionCode = value.value
    },

    handleChangeSecondaryIndustry(value) {
      this.contactInfo.secondaryIndustry = value
    },

    resetForm() {
      this.errorPhoneNumber = ''
      this.businessName = ''
      this.timeZone = ''
      this.registerAreaSelected = null
      this.yourBusinessName = this.user?.data?.businessInfo?.name || ''
      this.companyWebsite = this.user?.data?.businessInfo?.website || ''
      this.secondaryIndustrySelected = null
      this.contactInfo = {
        phoneRegionCode: 'VN',
        phone: '',
        secondaryIndustry: '',
      }
    },

    handleCloseModal() {
      this.resetForm()
      this.$emit('close-modal')
    },

    validatePhoneNumber() {
      const regExp = /^[0-9]*$/
      const regSpc = /\s/
      if (!this.contactInfo.phone || this.contactInfo.phone === '') {
        this.errorPhoneNumber = this.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.errorPhoneNumberRequired')
      } else if (!(regExp.test(this.contactInfo.phone) && !regSpc.test(this.contactInfo.phone) && this.contactInfo.phone.length <= 14 && this.contactInfo.phone.length > 2)) {
        this.errorPhoneNumber = this.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.errorPhoneNumberInvalid')
      } else {
        this.errorPhoneNumber = ''
      }
    },

    async submit() {
      this.validatePhoneNumber()
      this.$refs.businessFormValidation.validate().then(async success => {
        if (success && !this.errorPhoneNumber) {
          const params = {
            BCName: this.businessName,
            timezone: this.timeZone,
            companyName: this.yourBusinessName || this.user?.data?.businessInfo?.name,
            companyWebsite: this.companyWebsite || this.user?.data?.businessInfo?.website,
            registeredArea: this.registerAreaSelected.value,
            contactInfo: this.contactInfo,
          }
          await this.createBusinessAccount(params)
          if (this.status) {
            this.toastSuccess(this.$t('myAdAccountsPage.setupTab.step3.noticeCreateNewAccountSuccess'))
            this.resetForm()
            await this.getAccountInfo()
            this.$emit('close-modal')
          } else {
            this.errorStep2 = this.message
            setTimeout(() => {
              this.$router.go()
            }, 4000)
          }

          await this.getBusinessAccount()

          this.$emit('refresh-page')
        }
      })
    },
  },
}
</script>

<style lang="scss">
.phone-region-select {
  position: relative;
  width: 100%;
  .vs__dropdown-toggle {
    height: 48px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .vs__clear {
    display: none;
  }

  .vs__dropdown-menu {
    width: 435px !important;
    position: absolute !important;

    @media (max-width: 500px) {
      width: 320px !important;
    }
  }

  .vs__dropdown-option--selected {
    &::after {
      display: none;
    }
  }
}

.select-industry {
  .vs__selected-options {
    width: 80%;
    .vs__selected {
      width: 95%;
      white-space: nowrap;
      overflow-x: overlay;
    }
    .vs__selected::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }
}

.business-header {
  padding: 32px;
  padding-bottom: 0px;
  border-radius: var(--border-radius-base);

  .modal-title {
    font-size: 20px;
  }
  &.modal-header .close {
    padding: 0;
    background-color: transparent;
    top: 25px;
    font-size: 40px;
    position: absolute;
    right: 50px;
    transform: none;
    box-shadow: none;
    border-radius: 0px;
    &:hover {
      transform: none;
    }
  }
}

.business-body {
  padding: 32px 32px;
}

.business-content {
  border-radius: var(--border-radius-base);
}

.timezone-select {
  .vs__dropdown-toggle {
    height: 48px;
  }
  .custom-select {
    &:focus {
      border-color: #16213e;
    }
  }
}

.error-create-ads {
  margin-top: 24px;
  background: rgba(225, 28, 78, .1);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
  }
}

.phone-field-error{
  border: 1px solid #E11C4E !important;
}
</style>
