var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"data-testid":"modal-create-BCId","no-close-on-backdrop":"","centered":"","title":_vm.$t('account.modalCreateAdAccount.titleModal'),"hide-footer":"","content-class":"business-content","header-class":"business-header","body-class":"business-body"},on:{"close":_vm.handleCloseModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',[_c('validation-observer',{ref:"businessFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('b-form-group',{staticClass:"form-label",attrs:{"label":_vm.$t('myAdAccountsPage.setupTab.step3.labelBusinessCenterName'),"data-testid":"field-BCId-name"}},[_c('validation-provider',{attrs:{"id":"name","data-testid":"name-BCId","rules":"required","name":_vm.$t('myAdAccountsPage.setupTab.step3.labelBusinessCenterName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge width-custom w-100",class:errors.length > 0 ? 'is-invalid':null,attrs:{"data-testid":"group-BCId"}},[_c('b-form-input',{staticClass:"input-height",attrs:{"data-testid":"input-name-BC","placeholder":_vm.$t('account.modalCreateAdAccount.placeHolderBusinessCenterName'),"state":errors.length > 0 ? false : null},on:{"keyup":function($event){_vm.currentIndex = null}},model:{value:(_vm.businessName),callback:function ($$v) {_vm.businessName=$$v},expression:"businessName"}})],1),(errors[0])?_c('small',{staticClass:"text-danger d-block",staticStyle:{"margin-top":"10px"},attrs:{"data-testid":"error-BC-name"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('account.modalCreateAdAccount.labelTimezone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',{attrs:{"data-testid":"field-timezone"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v(_vm._s(_vm.$t('account.modalCreateAdAccount.labelTimezone')))])]),_c('v-select',{staticClass:"timezone-select",attrs:{"data-testid":"timezone-select","options":_vm.listTimeZone,"clearable":false,"placeholder":_vm.$t('account.modalCreateAdAccount.placeholderTimezone')},on:{"input":_vm.handleSelectTimeZone},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({attrs:{"data-testid":"chevron-icon"}},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.timeZone),callback:function ($$v) {_vm.timeZone=$$v},expression:"timeZone"}}),_c('small',{staticClass:"text-danger",attrs:{"data-testid":"error-timezone"}},[_vm._v(_vm._s(errors[0]))])],1)])]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('account.modalCreateAdAccount.labelRegisterArea')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',{attrs:{"data-testid":"field-register-area"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v(_vm._s(_vm.$t('account.modalCreateAdAccount.labelRegisterArea')))])]),_c('v-select',{staticClass:"timezone-select",attrs:{"id":"select-register-area","data-testid":"select-register-area","label":"name","placeholder":_vm.$t('account.modalCreateAdAccount.placeholderRegisterArea'),"clearable":false,"options":_vm.listRegisterArea},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}},{key:"option",fn:function(ref){
var name = ref.name;
var value = ref.value;
return [_c('strong',{attrs:{"id":("plan-" + value),"data-testid":("plan-" + value)}},[_vm._v(_vm._s(name))])]}}],null,true),model:{value:(_vm.registerAreaSelected),callback:function ($$v) {_vm.registerAreaSelected=$$v},expression:"registerAreaSelected"}}),_c('small',{staticClass:"text-danger",attrs:{"data-testid":"error-timezone"}},[_vm._v(_vm._s(errors[0]))])],1)])]}}])}),_c('div',{attrs:{"data-testid":"form-contact-info"}},[_c('p',{staticClass:"mt-2 title-content-step"},[_vm._v(" "+_vm._s(_vm.$t('profile.textContactInfo'))+" ")]),_c('b-row',[_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('b-form-group',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v(_vm._s(_vm.$t('account.modalCreateAdAccount.labelPhoneNumber')))])]),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('account.modalCreateAdAccount.labelPhoneNumber')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-filter"},[_c('b-input-group-prepend',{staticStyle:{"width":"30%"}},[_c('v-select',{staticClass:"d-inline-block input-group-merge width-custom input-height phone-region-select",attrs:{"id":"select-phone region","label":"name","clearable":false,"data-testid":"phone-region-option","options":_vm.phoneRegionOptions},on:{"input":_vm.handleChangePhoneRegion},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}},{key:"selected-option",fn:function(ref){
var callingCode = ref.callingCode;
var value = ref.value;
return [_c('span',{staticStyle:{"margin-right":"4px"}},[_vm._v(_vm._s(value))]),_c('span',[_vm._v(_vm._s(callingCode))])]}},{key:"option",fn:function(ref){
var callingCode = ref.callingCode;
var name = ref.name;
var value = ref.value;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(name)+"("+_vm._s(value)+")")]),_c('span',[_vm._v(_vm._s(callingCode))])])]}}],null,true),model:{value:(_vm.phoneRegionSelected),callback:function ($$v) {_vm.phoneRegionSelected=$$v},expression:"phoneRegionSelected"}})],1),_c('b-form-input',{staticClass:"d-inline-block input-group-merge width-custom input-height",class:{'phone-field-error': _vm.errorPhoneNumber},attrs:{"data-testid":"input-phone-number","placeholder":_vm.$t('account.modalCreateAdAccount.labelPhoneNumber')},on:{"wheel":_vm.handleMouseWheelInput,"keyup":_vm.validatePhoneNumber},model:{value:(_vm.contactInfo.phone),callback:function ($$v) {_vm.$set(_vm.contactInfo, "phone", $$v)},expression:"contactInfo.phone"}})],1),(errors[0] || _vm.errorPhoneNumber)?_c('small',{staticClass:"text-danger d-block",staticStyle:{"margin-top":"10px"},attrs:{"data-testid":"error-phone-number"}},[_vm._v(" "+_vm._s(errors[0] || _vm.errorPhoneNumber)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('account.modalCreateAdAccount.labelIndustry')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',{attrs:{"data-testid":"field-industry"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v(_vm._s(_vm.$t('account.modalCreateAdAccount.labelIndustry')))])]),_c('b-form-select',{staticClass:"timezone-select select-industry",class:{'text-placeholder': !_vm.secondaryIndustrySelected},attrs:{"id":"select-industry","data-testid":"select-industry"},on:{"input":_vm.handleChangeSecondaryIndustry},model:{value:(_vm.secondaryIndustrySelected),callback:function ($$v) {_vm.secondaryIndustrySelected=$$v},expression:"secondaryIndustrySelected"}},[_vm._l((_vm.industryOptions.filter(function (item) { return !item.group; })),function(parent){return _c('b-form-select-option',{key:parent.value,staticClass:"hidden",attrs:{"value":parent.value}},[_vm._v(" "+_vm._s(parent.name)+" ")])}),_vm._l((_vm.industryOptions.filter(function (item) { return item.group; })),function(parent){return _c('b-form-select-option-group',{key:parent.group,attrs:{"label":parent.group}},_vm._l((parent.options),function(child,index){return _c('b-form-select-option',{key:index,attrs:{"value":child.value}},[_vm._v(" "+_vm._s(child.name)+" ")])}),1)})],2),_c('small',{staticClass:"text-danger",attrs:{"data-testid":"error-industry"}},[_vm._v(_vm._s(errors[0]))])],1)])]}}])})],1)],1)],1),(!_vm.businessInfo)?_c('div',{attrs:{"data-testid":"form-business-info"}},[_c('p',{staticClass:"mt-2 title-content-step"},[_vm._v(" "+_vm._s(_vm.$t('profile.textGeneral'))+" ")]),_c('b-row',[_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('b-form-group',{staticClass:"form-label",attrs:{"label":_vm.$t('myAdAccountsPage.labelYourBusinessName'),"data-testid":"label-business-name"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('myAdAccountsPage.labelYourBusinessName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge width-custom w-100",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"data-testid":"input-business-name","placeholder":_vm.$t('account.modalCreateAdAccount.placeholderBusinessName'),"disabled":_vm.checkPlanUserToDisableInput,"state":errors.length > 0 ? false : null},on:{"keyup":function($event){_vm.currentIndex = null}},model:{value:(_vm.yourBusinessName),callback:function ($$v) {_vm.yourBusinessName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"yourBusinessName"}})],1),(errors[0])?_c('small',{staticClass:"text-danger d-block",staticStyle:{"margin-top":"10px"},attrs:{"data-testid":"error-business-name-step3"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,1355530430)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","cols":"12"}},[_c('b-form-group',{staticClass:"form-label",attrs:{"label":_vm.$t('myAdAccountsPage.labelCompanyWebsite'),"data-testid":"label-company-website"}},[_c('validation-provider',{attrs:{"rules":"url|verifyBusinessWebsite|required","name":_vm.$t('myAdAccountsPage.labelCompanyWebsite')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge width-custom w-100",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"data-testid":"input-company-website","placeholder":_vm.$t('account.modalCreateAdAccount.placeholderCompanyWebsite'),"disabled":_vm.checkPlanUserToDisableInput,"state":errors.length > 0 ? false : null,"type":_vm.url},on:{"keyup":function($event){_vm.currentIndex = null}},model:{value:(_vm.companyWebsite),callback:function ($$v) {_vm.companyWebsite=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"companyWebsite"}})],1),(errors[0])?_c('small',{staticClass:"text-danger d-block",staticStyle:{"margin-top":"10px"},attrs:{"data-testid":"error-company-website"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,1234371142)})],1)],1)],1)],1):_vm._e(),(_vm.errorStep2)?_c('div',{attrs:{"data-testid":"error-create-BCId"}},[_c('error-tag',{attrs:{"error":_vm.errorStep2}})],1):_vm._e(),_c('btn-loading',{staticClass:"mt-2 btn-height",attrs:{"data-testid":"btn-create-new-account-2","type":"submit","variant-convert":"btn-submit","pill":"","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('account.modalCreateAdAccount.btnCreateNewAccount'))+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }